.main-screen {

  .item {
    border: 1px dashed #e1e1e1;
    border-radius: 25px;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 15px;

    .in {
      padding: 15px;

      button.buttontext {
        border: none;
        border-radius: 5px;
        padding: 7px 14px;
      }

      .props {

        margin-top: 15px;

        p {
          font-weight: 500;
          line-height: 1.3;
          opacity: 0.8;

          span {
            color: #9E9E9E;
          }
        }
      }
    }

    .title {
      border-bottom: 1px dashed #e1e1e1;
      padding: 0 15px 15px;

      .title-span {
        text-transform: uppercase;
        font-weight: bold;
        color: #7264D6;
      }
    }
  }
}